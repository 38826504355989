<div id="wrapper">
  <!--header -->
  <header id="header">
    <div class="container">
      <div class="logo-left">
        <a><img src="assets/images/logo.png" alt="Cloudexcels"></a>
        <!-- <a href="index.html"><img src="assets/images/logo.png" alt="Cloudexcels"></a> -->
      </div>
      <div class="header-right">
        <ul class="header-top">
          <li class="header-phone"><a [href]="'tel:'+ location.phoneNumber">{{location.phoneNumber}}</a></li>
          <!--<li class="header-phone"><a href="tel:123456789">0170 5566722</a></li> -->
          <li class="header-main"><a [href]="'mailto:'+location.email">{{location.email}}</a></li>
          <!--<li class="header-main"><a href="mainto:frank.wanning@cloudexcels.com">info@cloudexcels.com</a></li> -->
          <li class="mobile-menu" id="mobile-menu" style="display: none;"><a href="#"></a><</li>
        </ul>
        <div class="menu-right">
          <span class="menu-close" id="menu-close" style="display:none;"></span>
          <ul>
            <li><a (click)="scrollSite('banner')">Home</a></li>
            <li><a (click)="scrollSite('about')">Warum Cloud Excels?</a></li>
            <li><a (click)="scrollSite('offers')">Unsere Leistungen</a></li>
            <li><a (click)="scrollSite('journey')">Möglichkeiten der Cloud</a></li>
            <li><a (click)="scrollSite('aboutAndCertified')">Über uns</a></li>
            <li><a (click)="scrollSite('footer')">Kontakt</a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <!--header -->

  <!--Banner-->
  <section id="banner" class="banner-section">
    <img src="assets/images/banner-bg.jpg" alt="Banner" width="1600" height="840">

    <div class="container">
      <h1>Take Your Business to the Next Level </h1>
      <!--<h3 style="color: white">Ihr Kompetenter IT-Partner aus Rhein-Main</h3>-->
      <h3 style="color: white;">IT-Infrastruktur und Software-Entwicklung in der Cloud</h3>
      <div class="header-button-wrap">
        <a class="main-btn" (click)="scrollSite('footer')">JETZT KONTAKT AUFNEHMEN</a>
        <!--<a (click)="goToCalendly()" target="_blank" class="main-btn">JETZT KONTAKT AUFNEHMEN</a>-->
        <!--<a href="https://calendly.com/cloud-excels/lift-and-shift?month=2020-04" target="_blank" class="main-btn">BERATUNGSTERMIN
          BUCHEN</a>-->
        <!--<a href="#" class="main-btn">NEWSLETTER SUBSCRIBE</a>-->
      </div>
    </div>

    <!--<img class="banner-consulting" src="assets/images/partner-logo.jpg" alt="Consulting Partner" width="171"
         height="161">-->
  </section>
  <!--Banner-->

  <!--Why CE Section-->
  <section id="about" class="about-section">
    <div class="whyCE" fxLayout="column" fxLayout.gt-md="row" fxLayoutGap.gt-md="20px"
         fxLayoutAlign="center center">
      <section class="CE">
        <h1 style=" font-size: 22px;">Warum Cloud Excels?</h1>
        <p>Die Digitalisierung nimmt immer weiter an Fahrt auf. Im gleichen Maße steigt der Bedarf an leistungsstarker
          und zukunftsfähiger IT-Infrastruktur, insbesondere in kleinen und mittleren Unternehmen.
          Cloud Excels ist Ihr kompetenter Dienstleister, wenn es um passgenau auf den jeweiligen Bedarf hin
          konzipierte, rentable und anwenderfreundliche IT-Lösungen geht.
          Ob Sie die in Ihrem Unternehmen vorhandene IT auf die Anforderungen des digitalen Zeitalter anpassen oder
          gänzlich neue Geschäftsfelder erschließen wollen:
          Cloud Excels ist Ihr regionaler Partner in der Rhein-Main Region!
        </p>
        <p>Cloud Computing, auch Datenwolke genannt, bezeichnet eine IT-Infrastruktur, die remote – also beispielsweise
          über das Internet – zur Verfügung gestellt wird.<br>
          Es gibt viele überzeugende Gründe, warum sich auch für Ihr Unternehmen der Umstieg auf die Cloud auszahlt:
        </p>
      </section>
    </div>

    <div class="list1" fxflex fxLayout="row" fxLayout.lt-sm="column"
         style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">
      <div class="listElement1" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
        <h2 style="font-size: 20px;">
          Kostenersparnis
        </h2>
        <p>Durch den Einsatz von Cloud Infrastruktur entsteht ein erhebliches Einsparpotential. Laufende Kosten für
          Entwicklung, Wartung und Betrieb von Computersystemen können erheblich reduziert werden!</p>
        <p class="moreInfolistElement1" matRipple [matRippleCentered]="false" [matRippleUnbounded]="true"
           (click)="openDialog('1')" style="color: #007db8">Mehr Information</p>
      </div>
      <div class="listElement2" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
        <h2 style="font-size: 20px;">
          Effizienzsteigerung
        </h2>
        <p>Cloud Excels steigert Ihre Wettbewerbsfähigkeit! Denn mit maßgeschneiderten Lösungen, die exakt auf Ihr
          Unternehmen zugeschnitten sind, kümmern wir uns um den zuverlässigen Betrieb Ihrer IT.</p>
        <p class="moreInfolistElement2" matRipple [matRippleCentered]="false" [matRippleUnbounded]="true"
           (click)="openDialog('2')" style="color: #007db8">Mehr Information</p>
      </div>
      <div class="listElement3" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
        <h2 style="font-size: 20px;">
          Flexibilität
        </h2>
        <p>Weltweit und mit jedem internetfähigen Endgerät auf Ihre Anwendungen zugreifen: Die Cloud machts möglich!
          Machen Sie sich und Ihre Geschäftsideen standortunabhängig und extrem flexibel. </p>
        <p class="moreInfolistElement3" matRipple [matRippleCentered]="false" [matRippleUnbounded]="true"
           (click)="openDialog('3')" style="color: #007db8">Mehr Information</p>
      </div>
    </div>

    <div class="list2" fxflex fxLayout="row" fxLayout.xs="column"
         style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">
      <div class="listElement1" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
        <h2 style="font-size: 20px;">
          Regionalität
        </h2>
        <p>Auch wenn das Internet die Welt zu einem Dorf macht: Wenn es mal schnell gehen muss, braucht es einen
          persönlichen Ansprechpartner aus der Region! Cloud Excels ist im Rhein-Main-Gebiet für Sie
          im Einsatz!</p>
        <p class="moreInfolistElement1" matRipple [matRippleCentered]="false" [matRippleUnbounded]="true"
           (click)="openDialog('4')" style="color: #007db8">Mehr Information</p>
      </div>
      <div class="listElement2" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
        <h2 style="font-size: 20px;">
          Preistransparenz
        </h2>
        <p>Bei uns gibt es keine versteckten Kosten. Sie erhalten von uns immer einen Festpreis über die von Ihnen
          beauftragte Leistung.</p>
        <p class="moreInfolistElement2" matRipple [matRippleCentered]="false" [matRippleUnbounded]="true"
           (click)="openDialog('5')" style="color: #007db8">Mehr Information</p>
      </div>
      <div class="listElement3" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
        <h2 style="font-size: 20px;">
          Neueste Technologien
        </h2>
        <p>Nie wieder langsame Rechner oder übervolle Festplatten: Mit unseren Cloud Lösungen verfügen Sie dauerhaft
          über leistungsstarke, zuverlässige und sichere Hardware! </p>
        <p class="moreInfolistElement3" matRipple [matRippleCentered]="false" [matRippleUnbounded]="true"
           (click)="openDialog('6')" style="color: #007db8">Mehr Information</p>
      </div>
    </div>
    <!--<div class="about-image">
      <img src="assets/images/about-img.png" alt="About Image" height="491" width="737">


      https://gsr.dev/material2-carousel/

    </div>-->
    <!--<div class="about-content">
      <h3 class="main-title left-aligin"><b>ABOUT</b> CLOUD EXCELS</h3>
      <p><b>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna volutpat ut wisi.</b></p>
      <p>Ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quistrty nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea comm consequ. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odi dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nullatui facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy niiiyioibh euismod tincidunt ut laoreet dolore magna volutpat.</p>
      <a class="main-btn">KNOW MORE</a>
    </div>-->
  </section>
  <!--about-->

  <!--core offer-->
  <section id="offers" class="offer">
    <div class="container">
      <h3 class="main-title" style="font-size: 20px">Unser Leistungsspektrum</h3>
      <ul>
        <li class="training">
          <div>
            <h4>Training</h4>
            <p>Wir schulen Ihre Mitarbeiter und machen sie fit für den Umgang mit den AWS Cloud-Diensten.</p>
          </div>
        </li>
        <li class="big-data">
          <div>
            <h4>Web & App Entwicklung</h4>
            <p>Cloud Excels unterstützt Sie bei der Entwicklung Ihrer individuellen Cloud-Umgebung.
              Mit eigens für Ihr Unternehmen entwickelter Software erhalten Sie effiziente Lösungen für Ihr
              Unternehmen!</p>
          </div>
        </li>
        <li class="cloud-migration">
          <div>
            <h4>Cloud Migration</h4>
            <p>Cloud Excels bringt Ihre Daten in die Cloud. Schnell, sicher und ohne Mehraufwand für Sie! Sie geben die
              Richtung vor, wir kümmern uns um den Rest!</p>
          </div>
        </li>
        <li class="architected">
          <div>
            <h4>Optimierungen</h4>
            <p>Cloud Excels optimiert Ihre bestehende AWS Architektur. Für Höchstleistung im täglichen Geschäft.</p>
          </div>
        </li>
        <li class="devops">
          <div>
            <h4>DevOps</h4>
            <p>DevOps verbessert die Qualität der Software, die Geschwindigkeit der Entwicklung und der Auslieferung
              sowie das Miteinander der beteiligten Teams. Wir bringen auch Ihr Team in den DevOps-mode!</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
  <!--core offer-->


  <!--journey-->
  <section id="journey" class="journey-section">
    <div class="container journey">
      <h3 class="main-title" style="font-size: 20px">WIE CLOUD EXCELS SOFTWARE IN DIE CLOUD TRANSFORMIERT</h3>
      <ul>
        <li class="lift-shift">
          <h4><span>Stage 1</span>Lift & Shift</h4>
          <p>Bei Ihnen im Unternehmen vorhandene Software wird in die Cloud migriert. Dieser Schritt bedingt keinerlei
            Änderungen an der Software.</p>
        </li>
        <li class="infrastructure">
          <h4><span>Stage 2</span>Automated Infrastructure</h4>
          <p>Die grundlegende Architektur der migrierten Software wird von uns auf den Einsatz in der Cloud optimiert.
            Dabei wird jeder Task, der heute noch manuell erledigt wird, vollständig automatisiert. Beispiele sind unter
            anderem Monitoring- und
            Überprüfungsaufgaben oder das Kapazitätsmanagement.</p>
        </li>
        <li class="containerized">
          <h4><span>Stage 3</span>Containerized Microservices</h4>
          <p>Die migrierte Software wird aufgesplittet – von einem großen Ganzen in ein Ökosystem aus Microservices.
            Jeder Microservice wird von uns
            individuell entwickelt, getestet, implementiert und skaliert.
            So ermöglichen wir ein Optimum an Skalabilität, Kosteneffizienz und Schnelligkeit bei der Entwicklung neuer
            Softwarebestandteile. </p>
        </li>
        <li class="serverless">
          <h4><span>Stage 4</span>Serverless</h4>
          <p>Das Verwalten und Betreiben eigener Netzwerk- und Serverinfrastruktur entfällt.
            Die Bereitstellung technischer Komponenten wie beispielsweise Klima- und Sicherheitslösungen, sowie
            geeigneter Räumlichkeiten gehören der Vergangenheit an. </p>
        </li>
      </ul>
      <a (click)="scrollSite('footer')" class="main-btn">JETZT KONTAKT AUFNEHMEN</a>
    </div>
  </section>
  <!--journey-->


  <!--about us and certified-->
  <section id="aboutAndCertified" class="certified-section">
    <div class="AboutUsHeadline" fxLayout="column" fxhide="false" fxLayout.gt-md="row" fxLayoutGap.gt-md="20px"
         fxLayoutAlign="center center">
      <section class="CE">
        <h2>CLOUD EXCELS – MIT SICHERHEIT EIN STARKER PARTNER</h2>
      </section>
    </div>
    <div class="aboutUs" fxflex fxLayout="row" fxLayout.lt-sm="column"
         style="flex-direction: row; box-sizing: border-box; display: flex; flex: 1 1 0%;">


      <div class="aboutUsElement1" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
        <p style="text-align: left"><b>Die Vorteile der Cloud für Ihr Unternehmen in aller Kürze</b></p>
        <p style="text-align: left"><b>IT-Leistungen aus der Cloud ...</b></p>
        <p style="text-align: left">
          <b>#</b> sind kostengünstig und reduzieren ihre IT-Kosten deutlich<br>
          <b>#</b> steigern die Flexibilität ihres Unternehmens<br>
          <b>#</b> machen den Betrieb und die Wartung von IT-Infrastruktur obsolet<br>
          <b>#</b> reduzieren Investitionskosten in IT-Infrastruktur<br>
          <b>#</b> erhöhen ihre Datensicherheit<br>
          <b>#</b> sind dynamisch und zeitnah skalierbar<br>
          <b>#</b> sind standortunabhängig<br>
        </p>

      </div>

      <div class="aboutUsElement2" fxflex style="flex: 1 1 0%; box-sizing: border-box;">
        <p style="text-align: left"><b>Wir sind gerne für Sie da!</b></p>
        <p style="text-align: left">Haben Sie eine Frage zu unseren Cloud Diensten oder möchten Sie sich unverbindlich
          und selbstverständlich kostenfrei über die Möglichkeiten informieren,
          die Cloud Excels für Ihr Unternehmen bereithält? <br>
          <br>
          Gerne können Sie uns jederzeit über das unten stehende Kontaktformular anschreiben.
          Während unserer Geschäftszeiten stehen wir Ihnen natürlich auch jederzeit telefonisch für ein persönliches
          Gespräch zur Verfügung.
          Wir freuen uns bereits darauf, bald auch Sie von den vielen Vorteilen der Cloud begeistern zu dürfen!</p>
      </div>
    </div>
  </section>
  <!--certified-->

  <!--footer -->
  <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="footer-form">
          <h3>KONTAKTFORMULAR</h3>

          <form class="contact-form" id="contact-enquiry-form" [formGroup]="form">

            <div class="form-wrap">
              <div class="field">
                <label>Name<em>*</em></label>
                <input matInput type="text" id="fname" name="FormName" formControlName="FormName">
              </div>
              <div class="field">
                <label>Email<em>*</em></label>
                <input matInput type="text" id="femail" name="FormEmail" formControlName="FormEmail">
              </div>
            </div>

            <div class="form-wrap">
              <div class="field">
                <label>Telefonnummer<em>*</em></label>
                <input matInput type="text" id="fphone" name="FormPhone" formControlName="FormPhone">
              </div>
              <div class="field">
                <label>Betreff<em>*</em></label>
                <input type="text" id="fsubject" name="FormSubject" formControlName="FormSubject">
              </div>
            </div>

            <div class="feilds">
              <label>Nachricht<em>*</em></label>
              <textarea class="msg" id="message" name="FormMessage" formControlName="FormMessage"></textarea>
            </div>

            <!--<div class="feilds recaptcha">
              <div class="g-recaptcha" data-sitekey="6LdsbOgUAAAAAF4CWROCGsctHuoudq2vFK8cUEan"></div>
            </div>-->

            <div class="feilds recaptcha">
              <re-captcha
                (resolved)="resolved($event)"
                siteKey="6LeOtTUaAAAAANXhVsf4FWiv_zD5J4D1lv-AYMWg"
              ></re-captcha>
            </div>

            <div class="button-submit">
              <div class="submit-btn-wrap read_btn all-btn ">
                <input type="submit" value="ABSCHICKEN" class="csubmit-btn" name="FormSubmit" (click)="onSendRequest()">
              </div>
              <div>
                <mat-error *ngIf="sendEmailError">
                  {{sendEmailErrormessage}}
                </mat-error>
              </div>
            </div>

          </form>
        </div>
        <div class="contact-details">
          <ul>
            <li class="footer-address">
              <span><b>Cloud Excels GmbH</b> 60385 Frankfurt am Main Arnsburger Str. 5</span>
            </li>
            <li class="footer-phone">
              <h5>Phone:</h5>
              <a [href]="'tel:'+ location.phoneNumber">{{location.phoneNumber}}</a>
              <!--<a href="tel:1234567890">+123 456 7890</a> -->
            </li>
            <li class="footer-email">
              <h5>Email:</h5>
              <a [href]="'mailto:'+location.email">{{location.email}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="container">



        <ul class="social-media">

          <li>
            <a (click)="goToFacebook()" target="_blank" style="cursor: pointer"><i class="fa fa-facebook"></i></a>
          </li>
          <li><a (click)="goToTwitter()" target="_blank" style="cursor: pointer"><i class="fa fa-twitter"></i></a></li>
          <li><a (click)="goToLinkedIn()" target="_blank" style="cursor: pointer"><i class="fa fa-linkedin"></i></a>
          </li>
        </ul>
        <p>© 2023 CLOUD EXCELS. All Rights Reserved. <a (click)="openImprint()" style="cursor: pointer">Impressum</a></p>
      </div>
    </div>
  </footer>
  <!--footer -->
</div>

<!-- cookie-content -->

<div class="cookie-container" *ngIf="cookieContent === true">
  <a>Wir nutzen Cookies auf unserer Website.<a style="color: #007db8; cursor: pointer" (click)="openImprint()"> Cookie-Details | Datenschutzerklärung | Impressum</a>
  </a>
  <button class="cookie-btn" style="background:#e84118" mat-button (click)="closeCookieContent()">Okay</button>
</div>
<!-- cookie-content -->

<a id="myBtn" title="Go to top" style="display: block;" (click)="scrollSite('banner')"></a>

