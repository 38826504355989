<h2 mat-dialog-title>Neuste Technologien</h2>
<mat-dialog-content class="mat-typography">

  <div class="flexbox-container">

    <div class="headline">
      <p>Mit den Cloud Diensten von Cloud Excels haben Sie Zugriff auf aktuelle, leistungsstarke und jederzeit zuverlässige Hardwarekomponenten. <br>
        <br>
        Die Leistungsanforderungen der jeweiligen Kundenlösungen in den Rechenzentren der Amazon Web Services (AWS) werden permanent überwacht. Damit ist sicher gestellt,
        dass unsere Kunden genau die Leistung erhalten, die wirklich benötigt wird!
        Und auch die Sicherheit Ihrer Daten erhöhen wir "ganz nebenbei". Denn dank kontinuierlicher Backups, ausgereifter Sicherheitstechnik und Hardware aus dem Premiumsektor sind Datenverluste, Spionageangriffe oder defekte Festplatten zukünftig auch in Ihrem Unternehmen kein Thema mehr!
      </p>
    </div>

    <div class="end">
      <button mat-button><a [href]="'mailto:'+location.email">{{location.email}}</a></button>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>

  </div>
</mat-dialog-content>

