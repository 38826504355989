<h2 mat-dialog-title>Effizienzsteigerung</h2>
<mat-dialog-content class="mat-typography">

  <div class="flexbox-container">

    <div class="headline">
      <p>In der heutigen Zeit sind Computersysteme unverzichtbar – in allen Branchen. Die Kurzlebigkeit der eingesetzen Hardware- und Softwarekomponenten
        stellt dabei gerade für kleine und mittlere Unternehmen eine Herausforderung dar. </p>

      <p>Denn nur dann, wenn die eingesetzte IT dem aktuellen Stand der Technik entspricht, ist Wettbewerbsfähigkeit gesichert.
        Das bedeutet regelmäßige, kostenintensive Neuanschaffungen von IT-Komponenten, Arbeitszeitverlust durch Einbau- oder Wartungsarbeiten und nicht selten unnötigen Ärger!<br>
        Cloud Excels steigert die Effizienz Ihres Unternehmens im Bereich IT deutlich!
      </p>
      <p>
        Unsere Cloud Lösungen machen Sie und Ihr Unternehmen unabhängig von der Hardware und sorgen dafür,
        dass Sie technologisch auf dem neuesten Stand sind. Durch unser umfangreiches, modulares Dienstleistungskonzept haben Sie zukünftig maximale IT-Leistung
        und den Kopf frei für Ihr Kerngeschäft!
      </p>
    </div>

    <div class="end">
      <button mat-button><a [href]="'mailto:'+location.email">{{location.email}}</a></button>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>

  </div>
</mat-dialog-content>

