import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

import {API} from '@aws-amplify/api';
import * as queries from './queries';

@Injectable()
export class DataService {
  cookieContent = new Subject<boolean>();

  // hoppingCardElement = new Subject<Orders>();
  constructor(private router: Router) {
  }

  closeCookieContent(trueFalse) {
    this.cookieContent.next(trueFalse);
  }

  async sendEmailQuery(values): Promise<any> {
    const oneTodo = await API.graphql({
      query: queries.sendEmailQuery, variables: {
        email: values.FormEmail,
        message: values.FormMessage,
        name: values.FormName,
        phone: values.FormPhone,
        subject: values.FormSubject
      }
    });
  }
}
