<h2 mat-dialog-title>Preistransparenz</h2>
<mat-dialog-content class="mat-typography">

  <div class="flexbox-container">

    <div class="headline">
      <p>Only pay for what you get – das ist unsere Maxime. Verlassen Sie sich auf absolute Kostentransparenz über die gesamte Vertragslaufzeit! <br>
        <br>
        Ob Cloud Infrastrukturprojekte oder Softwareentwicklung - die Kosten für unsere Leistungen sind für Sie jederzeit transparent.
      </p>

    </div>

    <div class="end">
      <button mat-button><a [href]="'mailto:'+location.email">{{location.email}}</a></button>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>

  </div>
</mat-dialog-content>

