import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {DataService} from './data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  cookieContent = true;
  title = 'ceWebsite';
  location = {
    phoneNumber: '069 46939512',
    email: 'kontakt@cloudexcels.com'
  };
  form: FormGroup;
  sendEmailError = false;
  sendEmailErrormessage: string;
  durationInSeconds = 5;
  captchaToken;

  // tslint:disable-next-line:max-line-length
  constructor(private fb: FormBuilder, private dataService: DataService, @Inject(DOCUMENT) private document: Document, private dialog: MatDialog,
              private router: Router, private snackBar: MatSnackBar) {
    this.sendEmailError = false;
  }

  goToCalendly(): void {
    this.document.location.href = 'https://calendly.com/cloud-excels/lift-and-shift?month=2020-04';
  }

  goToFacebook(): void {
    this.document.location.href = 'https://de-de.facebook.com/pages/category/Information-Technology-Company/Cloud-Excels-GmbH-314613519186169/';
  }

  goToTwitter(): void {
    this.document.location.href = 'https://twitter.com/cloud_excels';
  }

  goToLinkedIn(): void {
    this.document.location.href = 'https://www.linkedin.com/company/cloudexcels';
  }

  ngOnInit(): void {
    this.cookieContent = true;
    this.dataService.cookieContent.subscribe(change => {
      this.cookieContent = change;
    });
    this.form = this.fb.group({
      FormEmail: ['', Validators.email],
      FormName: ['', Validators.required],
      FormPhone: ['', Validators.required],
      FormSubject: ['', Validators.required],
      FormMessage: ['', Validators.required],
    });
  }

  async resolved(captchaResponse: string) {
    this.captchaToken = captchaResponse;
  }


  async onSendRequest(): Promise<any> {
    this.sendEmailError = false;
    if (!this.form.valid) {
      this.sendEmailErrormessage = 'Ein oder mehrere Felder haben einen Fehler. Bitte prüfen Sie und versuchen Sie es erneut.';
      this.sendEmailError = true;
      return false;
    } else if (this.captchaToken == null || this.captchaToken.length === 0) {
      this.sendEmailErrormessage = 'Ein oder mehrere Felder haben einen Fehler. Bitte prüfen Sie und versuchen Sie es erneut.';
      this.sendEmailError = true;
      return false;
    } else {
      await this.sendRequest(this.form.value);
      this.captchaToken = '';
      await this.openSuccessSnackBar();
    }
  }

  async sendRequest(formValues): Promise<any> {
    await this.dataService.sendEmailQuery(formValues);
  }

  async openSuccessSnackBar(): Promise<any> {
    this.snackBar.openFromComponent(SuccessRegisterMessageComponent, {
      duration: this.durationInSeconds * 1000,
      panelClass: ['green-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  openDialog(dialog) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60%';
    if (dialog === '1') {
      this.dialog.open(Dialog1Component);
    } else if (dialog === '2') {
      this.dialog.open(Dialog2Component);
    } else if (dialog === '3') {
      this.dialog.open(Dialog3Component);
    } else if (dialog === '4') {
      this.dialog.open(Dialog4Component);
    } else if (dialog === '5') {
      this.dialog.open(Dialog5Component);
    } else if (dialog === '6') {
      this.dialog.open(Dialog6Component);
    }
  }

  async openImprint() {
    this.dialog.open(ImprintComponent);
  }

  async scrollSite(id: string) {
    const el = await document.getElementById(id);
    await this.scrollCustomImplementation(el);
  }

  async scrollCustomImplementation(element: HTMLElement) {
      let start = null;
      const target = element && element ? element.getBoundingClientRect().top : 0;
      const firstPos = window.pageYOffset || document.documentElement.scrollTop;
      let pos = 0;

    // tslint:disable-next-line:only-arrow-functions
      (function() {
        const browser = ['ms', 'moz', 'webkit', 'o'];

        for (let x = 0, length = browser.length; x < length && !window.requestAnimationFrame; x++) {
          window.requestAnimationFrame = window[browser[x] + 'RequestAnimationFrame'];
          window.cancelAnimationFrame = window[browser[x] + 'CancelAnimationFrame'] || window[browser[x] + 'CancelRequestAnimationFrame'];
        }
      })();

      function showAnimation(timestamp) {
        if (!start) {
          start = timestamp || new Date().getTime();
        } // get id of animation


        const elapsed = timestamp - start;
        const progress = elapsed / 600; // animation duration 600ms
        // ease in function from https://github.com/component/ease/blob/master/index.js

        // tslint:disable-next-line:no-shadowed-variable
        const outQuad = function outQuad(n) {
          return n * (2 - n);
        };

        // tslint:disable-next-line:max-line-length
        const easeInPercentage = +outQuad(progress).toFixed(2); // if target is 0 (back to top), the position is: current pos + (current pos * percentage of duration)
        // if target > 0 (not back to top), the positon is current pos + (target pos * percentage of duration)

        pos = target === 0 ? firstPos - firstPos * easeInPercentage : firstPos + target * easeInPercentage;
        window.scrollTo(0, pos);
        // console.log(pos, target, firstPos, progress);

        if (target !== 0 && pos >= firstPos + target || target === 0 && pos <= 0) {
          cancelAnimationFrame(start);

          if (element) {
            element.setAttribute('tabindex', String(-1));
            element.focus();
          }

          pos = 0;
        } else {
          window.requestAnimationFrame(showAnimation);
        }
      }

      window.requestAnimationFrame(showAnimation);
  }


  closeCookieContent() {
    this.cookieContent = false;
  }

}


@Component({
  selector: 'app-success-register-message',
  templateUrl: 'snackbarSuccess.html',
  styles: [`
    .container1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `],
})
export class SuccessRegisterMessageComponent {
}

@Component({
  selector: 'app-dialog-content-1-dialog',
  templateUrl: 'dialog-content-dialog-1.html',
  styleUrls: ['./dialog.css']
})
export class Dialog1Component {
  location = {
    phoneNumber: 1234567890,
    email: 'kontakt@cloudexcels.com'
  };
}

@Component({
  selector: 'app-dialog-content-2-dialog',
  templateUrl: 'dialog-content-dialog-2.html',
  styleUrls: ['./dialog.css']
})
export class Dialog2Component {
  location = {
    phoneNumber: 1234567890,
    email: 'kontakt@cloudexcels.com'
  };
}

@Component({
  selector: 'app-dialog-content-3-dialog',
  templateUrl: 'dialog-content-dialog-3.html',
  styleUrls: ['./dialog.css']
})
export class Dialog3Component {
  location = {
    phoneNumber: 1234567890,
    email: 'kontakt@cloudexcels.com'
  };
}

@Component({
  selector: 'app-dialog-content-4-dialog',
  templateUrl: 'dialog-content-dialog-4.html',
  styleUrls: ['./dialog.css']
})
export class Dialog4Component {
  location = {
    phoneNumber: 1234567890,
    email: 'kontakt@cloudexcels.com'
  };
}

@Component({
  selector: 'app-dialog-content-5-dialog',
  templateUrl: 'dialog-content-dialog-5.html',
  styleUrls: ['./dialog.css']
})
export class Dialog5Component {
  location = {
    phoneNumber: 1234567890,
    email: 'kontakt@cloudexcels.com'
  };
}

@Component({
  selector: 'app-dialog-content-6-dialog',
  templateUrl: 'dialog-content-dialog-6.html',
  styleUrls: ['./dialog.css']
})
export class Dialog6Component {
  location = {
    phoneNumber: 1234567890,
    email: 'kontakt@cloudexcels.com'
  };
}

@Component({
  selector: 'app-imprint',
  templateUrl: 'imprint.html',
})
export class ImprintComponent implements OnInit {
  cookieContent = true;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.cookieContent = true;
  }

  closeCookieContent() {
    this.dataService.closeCookieContent(false);
  }
}





