import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Dialog1Component } from './app.component';
import { Dialog2Component } from './app.component';
import { Dialog3Component } from './app.component';
import { Dialog4Component } from './app.component';
import { Dialog5Component } from './app.component';
import { Dialog6Component } from './app.component';
import { ImprintComponent} from './app.component';
import {SuccessRegisterMessageComponent} from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {DataService} from './data.service';
import {MatListModule} from '@angular/material/list';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import Amplify from '@aws-amplify/core';
const myAppConfig = {
  aws_appsync_graphqlEndpoint: 'https://mkjxfkd6tbgnfn3txuu5gcjstq.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-o6wgbqrq2jddfmid36hhz56hvq',
};

Amplify.configure(myAppConfig);

@NgModule({
  declarations: [
    AppComponent,
    Dialog1Component,
    Dialog2Component,
    Dialog3Component,
    Dialog4Component,
    Dialog5Component,
    Dialog6Component,
    ImprintComponent,
    SuccessRegisterMessageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatSliderModule,
    BrowserAnimationsModule,
    MatRippleModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSnackBarModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
