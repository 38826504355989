<h2 mat-dialog-title>Regionalität</h2>
<mat-dialog-content class="mat-typography">

  <div class="flexbox-container">

    <div class="headline">
      <p>Von unserem Hauptsitz in Frankfurt am Main aus betreuen wir individuell kleine und mittlere, als auch Groß-Unternehmen.<br>
        <br>
        Regional, authentisch, transparent – wir möchten nicht nur "irgendein" IT-Dienstleister sein, sondern der ganz persönliche "Möglichmacher",
        der bei Bedarf auch bei Ihnen vor Ort tätig wird. Mit unserem Fokus auf das Rhein-Main-Gebiet setzen wir ein Zeichen für diese Metropolregion.
      </p>
    </div>

    <div class="end">
      <button mat-button><a [href]="'mailto:'+location.email">{{location.email}}</a></button>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>

  </div>
</mat-dialog-content>

