<h2 mat-dialog-title>Flexibilität</h2>
<mat-dialog-content class="mat-typography">

  <div class="flexbox-container">

    <div class="headline">
      <p>Ein Höchstmaß an Flexibilität ist eine Grundvoraussetzung für Unternehmen, um heute und in Zukunft wettbewerbsfähig zu sein.
        Cloud Excels bietet Ihnen eine Plattform für den weltweiten, standortunabhängigen Zugriff auf Ihre Geschäftsdaten.
      </p>
      <p>
        Ob Smartphone, Notebook oder stationärer Rechner: Wir sorgen dafür, dass Sie überall Zugriff auf ihre Unternehmenssoftware haben.
        Schnell skalierbare Systeme, die sich leicht erweitern und einfach warten lassen – dafür steht Cloud Excels!
      </p>
      <p>
        Nehmen Sie am besten gleich Kontakt mit uns auf!
      </p>

    </div>

    <div class="end">
      <button mat-button><a [href]="'mailto:'+location.email">{{location.email}}</a></button>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>

  </div>
</mat-dialog-content>

