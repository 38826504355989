<h2 mat-dialog-title>Kostenersparnis</h2>
<mat-dialog-content class="mat-typography">

  <div class="flexbox-container">

    <div class="headline">
      <p>Die Auslagerung der unternehmenseigenen IT-Infrastruktur in die Cloud macht Hardwareanschaffung so gut wie überflüssig.<br>
        Kostenintensive Investitionen in leistungsstarke Netzwerk- und Computertechnologien gehören der Vergangenheit an – die komplette Verarbeitung Ihrer Daten findet ausgelagert in einem Rechenzentrum statt.
      </p>

      <p>
        Regelmäßige Neuanschaffungen wegen veralteter Computertechnik gehört ebenso der Vergangenheit an wie Ausfallzeiten durch Wartungen oder Defekte.
        Die effizienten Softwaresysteme von Cloud Excels kommen dabei völlig ohne langfristige Lizenzkosten aus.
        Gerne beraten wir Sie in einem persönlichen Gespräch ausführlich zu den Kostenersparnissen, die durch Cloud Computing für Ihr Unternehmen möglich sind.
      </p>

    </div>

    <div class="end">
      <button mat-button><a [href]="'mailto:'+location.email">{{location.email}}</a></button>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>

  </div>
</mat-dialog-content>

